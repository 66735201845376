<template>
  <div>
    <form @submit.prevent="saveBankInfo" autocomplete="off">
      <div class="columns">
        <div class="column is-8 is-offset-2 is-size-4 has-text-centered">
          ¿Cómo deseas recibir tu crédito?
        </div>
      </div>
      <!-- <div class="columns is-multiline">
        <div class="column is-6 is-offset-3">
          <li
            v-for="accountType in eventTypeEnable"
            :key="accountType.idx"
            class="mt-2"
            :style="{ opacity: accountType.disabled ? 0.2 : 1 }"
          >
            <label>
              <input
                type="radio"
                :value="accountType.value"
                :id="accountType.idx"
                name="disbursementMethodId"
                v-model="$v.disbursementMethodId.$model"
                :disabled="accountType.disabled"
              />
              {{ accountType.label }}
            </label>
          </li>
        </div>
      </div> -->
      <div class="columns">
        <div class="column is-6 is-offset-3 is-size-4 has-text-centered">
          Confirma tus datos bancarios
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-6 is-offset-3">
          <div class="field">
            <label class="label required">
              Seleccione el banco donde quieres que te desembolsemos tu crédito
            </label>
            <div
              class="select is-fullwidth"
              :class="{
                'is-danger': $v.selectedBank.$error
              }"
            >
              <select v-model="selectedBank" @change="handleBankSelection">
                <option value="">Seleccione una opción</option>
                <option
                  v-for="bank in listBanks"
                  :key="bank.value"
                  :value="bank.value"
                >
                  {{ bank.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!-- Formularios condicionales -->
      <div v-if="selectedBank">
        <!-- Formulario para bancos del wallet -->
        <div v-if="isWalletBank" class="columns is-multiline">
          <div class="column is-6 is-offset-3">
            <div class="field">
              <label class="label"
                >¿Cuáles son los últimos 4 dígitos de tu número celular?</label
              >
              <div
                class="select is-fullwidth"
                :class="{ 'is-danger': $v.accountSequence.$error }"
              >
                <select v-model="$v.accountSequence.$model">
                  <option value="">Seleccione una opción</option>
                  <option
                    v-for="account in walletAccounts"
                    :key="account.value"
                    :value="account.value"
                  >
                    {{ account.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="field">
              <label class="label">Ingresa tu número de celular:</label>
              <input
                class="input"
                type="text"
                v-model.trim="$v.confirmAccount.$model"
                :class="{ 'is-danger': $v.confirmAccount.$error }"
                placeholder="**** **** **** ****"
              />
              <p class="help">
                Debe coincidir con el seleccionado en la lista.
              </p>
            </div>
          </div>
        </div>

        <!-- Formulario para otros bancos -->
        <div v-else class="columns is-multiline">
          <div class="column is-6 is-offset-3">
            <div class="field">
              <label class="label">La Cuenta contiene esta secuencia:</label>
              <div
                class="select is-fullwidth"
                :class="{ 'is-danger': $v.accountSequence.$error }"
              >
                <select v-model="$v.accountSequence.$model">
                  <option value="">Seleccione una opción</option>
                  <option
                    v-for="account in bankAccounts"
                    :key="account.value"
                    :value="account.value"
                  >
                    {{ account.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="field">
              <label class="label">Confirma tu cuenta:</label>
              <input
                class="input"
                type="text"
                v-model.trim="$v.confirmAccount.$model"
                :class="{ 'is-danger': $v.confirmAccount.$error }"
                placeholder="**** **** **** ****"
              />
            </div>
            <div class="field">
              <label class="label">Tipo de cuenta:</label>
              <div
                class="select is-fullwidth"
                :class="{ 'is-danger': $v.accountType.$error }"
              >
                <select v-model="$v.accountType.$model">
                  <option value="">Seleccione una opción</option>
                  <option
                    v-for="accountType in getAccountType"
                    :key="accountType.value"
                    :value="accountType.value"
                  >
                    {{ accountType.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-4 is-offset-4">
          <div class="buttons">
            <button
              class="button is-primary is-medium is-fullwidth"
              :disabled="isLoading || $v.$anyError"
            >
              Continuar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { methodIdPost, videoIDGet } from "@/services/user"
import { mapGetters, mapActions, mapMutations } from "vuex"
import validatePermissions from "../../helpers/validatePermissions"
import { required, requiredIf } from "vuelidate/lib/validators"
import { getBankAccounts, bankInformation } from "@/services/user"
export default {
  data() {
    return {
      isLoading: false,
      accountSequence: "",
      confirmAccount: "",
      accountType: "",
      accounts: [],
      walletAccounts: [],
      walletBanks: [],
      bankAccounts: [],
      listBanks: [],
      selectedBank: "",
      isWalletBank: false,
      disbursementMethodId: "",
      videoId: "",
      error: ""
    }
  },
  async beforeMount() {
    validatePermissions(
      "DAT_BAN",
      "No tiene permisos para gestionar a un cliente en la pantalla de Datos Bancarios"
    )
    try {
      this.videoId = await videoIDGet(this.getDocumentNumber, this.getLoan.id)
      console.log("this.videoId", this.videoId)
    } catch (err) {
      this.error = err
    }
  },
  validations: {
    // disbursementMethodId: { required },
    selectedBank: { required },
    accountSequence: { required },
    confirmAccount: {
      required,
      customValidation: function() {
        return this.isWalletBank ? this.confirmAccount.length === 10 : true
      }
    },
    accountType: {
      required: requiredIf(function() {
        return this.isWalletBank == false
      })
    }
  },
  mounted() {
    let loanActive = this.getLoanForBankAccounts
    if (loanActive !== undefined) {
      this.addLoanActive(loanActive)
      this.getUserAccount()
    }
  },
  methods: {
    handleBankSelection() {
      this.isWalletBank = this.walletBanks.some(
        bank => bank.value === this.selectedBank
      )
      if (this.$v) {
        this.accountType = ""
        this.accountSequence = ""
        this.confirmAccount = ""
        this.$v.$reset()
      }
      console.log("banco seleccionado", this.isWalletBank)
    },
    async getUserAccount() {
      try {
        let response = await getBankAccounts(
          this.getDocumentNumber,
          this.getLoanForBankAccounts.id
        )
        // const response = {
        //   accounts: ["1295"],
        //   bankNames: ["BANCOLOMBIA", "BANCO BBVA"],
        //   walletAccounts: ["1234", "5678", "9012"],
        //   walletNames: ["CUENTANEQUI", "DAVIPLATA"]
        // }
        // this.accounts = response
        this.accounts = response.accounts
        const normalize = str =>
          str.toLowerCase().replace(/[\s.,/#!$%^&*;:{}=\-_`~()]/g, "")
        if (response.walletAccounts.length > 0) {
          this.walletBanks = this.getBank.filter(item => {
            const normalizedLabel = normalize(item.label)
            return response.walletNames.some(term => {
              const normalizedTerm = normalize(term)
              return (
                normalizedLabel.includes(normalizedTerm) ||
                normalizedTerm.includes(normalizedLabel)
              )
            })
          })
        }
        if (response.walletAccounts.length > 0) {
          this.walletAccounts = response.walletAccounts.map(v => ({
            value: v,
            label: `--- ---${v}`
          }))
        }
        if (response.accounts.length > 0) {
          this.bankAccounts = response.accounts.map(v => ({
            value: v,
            label: `---- ---- ---- ${v}`
          }))
          this.listBanks = this.getBank
        } else {
          this.listBanks = this.walletBanks
        }
      } catch (error) {
        this.accounts = [
          { id: -1, value: "No se pudo obtener las cuentas del usuario" }
        ]
        this.$notyf.error(error)
      }
    },
    async saveBankInfo() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let validAccount = true
        if (this.accounts.length > 0) {
          validAccount = new RegExp(
            `.*${this.accountSequence.slice(-4)}$`
          ).test(this.confirmAccount)
        }
        if (validAccount) {
          let loan = this.getApprovedOrApprovedAwaitingSignature
          if (loan !== undefined) {
            try {
              this.isLoading = true
              const finalBank = this.walletBanks.find(
                bank => bank.value === Number(this.selectedBank)
              )
              const resultWallet = finalBank ? true : false
              if (resultWallet) {
                this.accountType = this.getAccountType[0].value
              }
              await methodIdPost(this.getLoan.id, {
                disbursementMethodId: 1406
              })
              await bankInformation(this.getDocumentNumber, loan.id, {
                bank: this.selectedBank,
                accountType: this.accountType,
                account: this.confirmAccount,
                automaticDebit: true
              })

              await this.updateStatus(this.getDocumentNumber)
              this.completeFormStep("bankInformation")
            } catch (error) {
              this.$notyf.error(
                "Ocurrio un error mientras se enviaba tu información."
              )
              this.isLoading = false
            }
          } else {
            this.$notyf.error(
              "No existe un prestamo con el estado 'Approved' o 'Approved awaiting signature'"
            )
          }
        } else {
          if (this.isWalletBank) {
            this.$notyf.error(
              "No coincide el número de celular ingresado con el seleccionado"
            )
          } else {
            this.$notyf.error(
              "No coincide la cuenta ingresada con la seleccionada"
            )
          }
        }
      } else {
        this.$notyf.error("Seleccione una opción")
      }
    },
    ...mapMutations({
      completeFormStep: "user/completeFormStep",
      addLoanActive: "user/addLoanActive"
    }),
    ...mapActions({
      updateStatus: "user/updateStatus"
    })
  },
  computed: {
    ...mapGetters({
      getLoan: "user/getApprovedOrApprovedAwaitingSignature",
      getEventType: "user/getEventType",
      getBank: "user/getBank",
      getAccountType: "user/getAccountType",
      getDocumentNumber: "user/getDocumentNumber",
      getLoanForBankAccounts: "user/getLoanForBankAccounts",
      getApprovedOrApprovedAwaitingSignature:
        "user/getApprovedOrApprovedAwaitingSignature",
      getApprovedAwaitingSignature: "user/getApprovedAwaitingSignature"
    }),
    eventTypeEnable() {
      return this.getEventType
        .map(obj => {
          let disabled = !this.videoId.successfully ? true : false
          if (obj.value === 1406) {
            return {
              ...obj,
              idx: 1,
              label: "Desembolso a mi cuenta bancaria",
              disabled
            }
          } else if (obj.value === 1405) {
            return {
              ...obj,
              idx: 3,
              label: "Compra en comercios y tiendas aliadas",
              description:
                "(El crédito se desembolsa directamente al comercio)",
              disabled
            }
          } else if (obj.value === 1401) {
            return {
              ...obj,
              idx: 2,
              label: "Desembolso en efectivo ",
              disabled: false
            }
          } else {
            return {
              ...obj
            }
          }
        })
        .filter(obj => {
          if (obj.value === 1406 || obj.value === 1405 || obj.value === 1401) {
            return true
          }
          return false
        })
        .sort((a, b) => {
          if (a.idx > b.idx) {
            return 1
          }
          if (a.idx < b.idx) {
            return -1
          }
          return 0
        })
    }
  }
}
</script>

<style></style>
